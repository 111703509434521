import React, { ReactNode } from 'react'
import { SwitchTransition, CSSTransition } from 'react-transition-group'
import fadeStyles from './Fade.module.scss'

interface Props {
  children: ReactNode
  transitionKey: number | string
  nodeRef: React.RefObject<HTMLDivElement>
}

export const transitionDuration = 200

export default function TransitionQuestion(props: Props) {
  const { children, transitionKey, nodeRef } = props
  return (
    <SwitchTransition mode="out-in">
      <CSSTransition
        nodeRef={nodeRef}
        timeout={transitionDuration}
        classNames={{ ...fadeStyles }}
        key={transitionKey}
      >
        <div ref={nodeRef}>{children}</div>
      </CSSTransition>
    </SwitchTransition>
  )
}
