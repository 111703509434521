import questionData from 'src/assets/questiondata/questions.json'
import expertAnswers from 'src/assets/questiondata/expertanswers.json'
import experts from 'src/assets/questiondata/experts.json'
import populationAnswers from 'src/assets/questiondata/population.json'
import uiTexts from 'src/assets/questiondata/uitexts.json'
import faq from 'src/assets/questiondata/faq.json'
import authors from 'src/assets/questiondata/authors.json'

export type ExpertData = typeof expertAnswers[0]
export type PopulationData = typeof population[0]
export type Expert = typeof experts[0]

type Q = typeof questions[0]
export interface Question extends Q {
  definition?: {
    token: string
    text: string
    url?: string
    source?: string
  }
}

const questions = questionData
const { questions: population, ageGroups, segments } = populationAnswers
export {
  questions,
  population,
  ageGroups,
  experts,
  expertAnswers,
  uiTexts,
  faq,
  authors,
  segments,
}
