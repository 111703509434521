import { useRef, useLayoutEffect } from 'react'

const renderingInBrowser = typeof window !== 'undefined'

interface Position {
  x: number
  y: number
}

function getScrollPosition(): Position {
  if (!renderingInBrowser) {
    return {
      x: 0,
      y: 0,
    }
  }

  const targetPosition = document.body.getBoundingClientRect()
  return { x: targetPosition.left, y: targetPosition.top }
}

function useScrollPosition(
  effect: (position: Position) => void,
  dependencies?: ReadonlyArray<any>
) {
  const bodyScrollPosition = useRef(getScrollPosition())
  const throttleTimeout = useRef<NodeJS.Timeout | undefined>()

  useLayoutEffect(() => {
    function callBack() {
      const currentPosition = getScrollPosition()
      effect(currentPosition)
      bodyScrollPosition.current = currentPosition
      throttleTimeout.current = undefined
    }

    function handleScroll() {
      if (!throttleTimeout.current) {
        throttleTimeout.current = setTimeout(callBack, 200)
      }
    }

    window.addEventListener('scroll', handleScroll)
    callBack()

    return () => {
      window.removeEventListener('scroll', handleScroll)
      clearTimeout(throttleTimeout.current!)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependencies)
}

export default useScrollPosition
