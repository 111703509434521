import env from '../env'

const Routes = {
  answer: `${env.appUrl}/api/v1/answers`,
  learned: `${env.appUrl}/api/v1/learned`,
  feedback: `${env.appUrl}/api/v1/feedback`,
}

export const postAnswers = (
  data: {
    questionNumber: number
    answer: 'yes' | 'no'
  }[]
) => {
  return postJson(Routes.answer, JSON.stringify(data))
}

export function postLearned(learned: boolean) {
  return postJson(Routes.learned, JSON.stringify({ learned }))
}

export function postFeedback(feedback: {
  learned: boolean
  opinion?: string
  yearOfBirth?: string
  gender?: string
}) {
  return postJson(Routes.feedback, JSON.stringify(feedback))
}

const postJson = (url: string, data: BodyInit): Promise<string> => {
  return fetch(url, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: data,
  }).then((response) => {
    if (!response.ok) {
      throw new Error(response.statusText)
    }
    return response.statusText
  })
}

export default { Routes }
