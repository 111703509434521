import { useState } from 'react'
import { NavigationRoute } from 'src/routes/NavigationContext'
import browserRoutes from 'src/routes/browser'
import { tail } from 'lodash-es'

function useNavigator() {
  const [routes, setRoutes] = useState<ReadonlyArray<NavigationRoute>>([
    {
      name: browserRoutes.start,
    },
  ])

  // enable passing of parameters during pop by merging
  // them with to the current route params
  function pop(params?: any) {
    const routesAfterPop = tail(routes)
    routesAfterPop[0].params = {
      ...routesAfterPop[0].params,
      ...params,
    }
    setRoutes(routesAfterPop)
  }

  function push(route: NavigationRoute) {
    setRoutes((routes) => [route].concat(routes))
  }

  return {
    pop,
    push,
    routes,
  }
}

export default useNavigator
