import React from 'react'
import styles from './QuestionProgress.module.scss'
import cx from 'classnames'
import navButton from 'src/assets/images/nav-button.svg'
import activeNavButton from 'src/assets/images/nav-button-active.svg'

interface Props {
  questionNumber: number
  lastQuestionNumber: number
  answerIsGiven: boolean
  onNextClick: () => void
  onPreviousClick: () => void
}

export default function QuestionProgress({
  questionNumber,
  lastQuestionNumber,
  answerIsGiven,
  onNextClick,
  onPreviousClick,
}: Props) {
  let circles = []
  for (let ix = 0; ix < lastQuestionNumber; ix++) {
    if (ix === questionNumber - 1) {
      circles.push(<div key={`dot${ix}`} className={styles.ActiveDot} />)
    } else {
      circles.push(<div key={`dot${ix}`} className={styles.InactiveDot} />)
    }
  }

  return (
    <div className={styles.DotContainer}>
      <button className={cx(styles.Button)} onClick={onPreviousClick}>
        <img
          src={questionNumber === 1 ? navButton : activeNavButton}
          className={styles.TopNavButton}
          alt="Nuoli vasemmalle"
        />
      </button>
      {circles}
      <button
        className={cx(styles.Button)}
        onClick={answerIsGiven ? onNextClick : undefined}
      >
        <img
          src={answerIsGiven ? activeNavButton : navButton}
          className={cx(styles.TopNavButton, styles.RightNavButton)}
          alt="Nuoli oikealle"
        />
      </button>
    </div>
  )
}
