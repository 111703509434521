import React, { useRef } from 'react'
import styles from './ShareSheet.module.scss'
import { ReactComponent as CloseButton } from 'src/assets/images/close-button.svg'
import hero from 'src/assets/images/somejako.jpg'
import ChallengeYourFriends from '../ChallengeYourFriends'
import { uiTexts } from 'src/util/questionData'
import useClickOutside from 'src/util/useClickOutside'

interface Props {
  onClose: () => void
}

function ShareSheet({ onClose }: Props) {
  const { subtitle } = uiTexts.some
  const popup = useRef<HTMLDivElement>(null)

  useClickOutside(popup, () => onClose())

  return (
    <div className={styles.Container} ref={popup}>
      <div className={styles.CloseButtonContainer}>
        <button className={styles.Button} onClick={onClose}>
          <CloseButton />
        </button>
      </div>
      <img src={hero} alt="Henkilö tekee valintoja" className={styles.Hero} />
      <ChallengeYourFriends subtitle={subtitle} />
    </div>
  )
}

export default ShareSheet
