import React, { useState, useContext } from 'react'
import styles from './InfoMenu.module.scss'
import cx from 'classnames'
import NavigationContext from 'src/routes/NavigationContext'
import { uiTexts } from 'src/util/questionData'
import browserRoutes from 'src/routes/browser'

interface Props {}

export default function InfoMenu(props: Props) {
  const [navHidden, setNavHidden] = useState(true)
  const { faq, feedback, authors, feedbackUrl } = uiTexts.common

  const { push, current } = useContext(NavigationContext)

  const onClick = () => setNavHidden(!navHidden)

  const click = (name: string) => {
    push({ name })
    setNavHidden(true)
  }

  const faqClick = () => click(browserRoutes.faq)
  const authorsClick = () => click(browserRoutes.authors)

  if (current?.params?.hideInfo) {
    return null
  }

  return (
    <div className={styles.InfoContainer}>
      <div onClick={onClick} className={styles.Info}>
        ?
      </div>
      <nav className={cx(styles.Nav, { [styles.Hidden]: navHidden })}>
        <ul>
          <li>
            <a
              href={feedbackUrl}
              className={styles.Link}
              target="_blank"
              rel="noopener noreferrer"
            >
              {feedback}
            </a>
          </li>
          <li onClick={faqClick}>{faq}</li>
          <li onClick={authorsClick}>{authors}</li>
        </ul>
      </nav>
    </div>
  )
}
