import React, { useState } from 'react'
import styles from './Expert.module.scss'
import { ReactComponent as ThumbUp } from 'src/assets/images/thumb-up.svg'
import { ReactComponent as ThumbDown } from 'src/assets/images/thumb-down.svg'
import { ReactComponent as Chevron } from 'src/assets/images/chevron.svg'
import cx from 'classnames'
import { uiTexts } from 'src/util/questionData'

export interface ExpertProps {
  image: string
  name: string
  profession: string
  question: string
  answer: 'yes' | 'no'
  reasoning: string
  timestamp: string
}

function Expert({ image, answer, name, reasoning, timestamp }: ExpertProps) {
  const ThumbIcon = answer === 'yes' ? ThumbUp : ThumbDown
  const [isExpanded, setIsExpanded] = useState(false)
  const { answered } = uiTexts.answer

  return (
    <button
      className={cx(styles.Button, styles.Container)}
      onClick={() => setIsExpanded((e) => !e)}
    >
      <div className={styles.Expert}>
        <div
          className={styles.Avatar}
          style={
            image
              ? {
                  backgroundImage: `url(${require('src/assets/images/' +
                    image)})`,
                }
              : undefined
          }
        >
          <ThumbIcon className={styles.Thumb} />
        </div>
        <p className={styles.ExpertName}>{name}</p>
        <Chevron
          className={cx(
            styles.Chevron,
            isExpanded ? styles.InvertedChevron : undefined
          )}
        />
      </div>
      {isExpanded && (
        <div>
          <p className={styles.Reasoning}>"{reasoning}"</p>
          <p className={styles.Timestamp}>
            <i>{`${answered} ${timestamp}.`}</i>
          </p>
        </div>
      )}
    </button>
  )
}

export default Expert
