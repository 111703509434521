import React, { useContext } from 'react'
import styles from './Experts.module.scss'
import graduation from 'src/assets/images/graduation.svg'
import arrow from 'src/assets/images/expert-arrow.svg'
import { ExpertData, experts, uiTexts } from 'src/util/questionData'
import { take, orderBy } from 'lodash-es'
import NavigationContext from 'src/routes/NavigationContext'
import browserRoutes from 'src/routes/browser'

interface Props {
  answers: ExpertData['answers']
  questionNumber: number
}

const NUMBER_OF_EXPERTS_TO_SHOW = 7

function Experts({ answers = [], questionNumber }: Props) {
  const expertsToShow = take(
    orderBy(answers, 'expertName'),
    NUMBER_OF_EXPERTS_TO_SHOW
  )
  const { push } = useContext(NavigationContext)
  const { title, subtitle } = uiTexts.question

  return (
    <button
      className={styles.Container}
      onClick={() =>
        push({
          name: browserRoutes.expertAnswer,
          params: {
            questionNumber,
            hideInfo: true,
          },
        })
      }
    >
      <div className={styles.Experts}>
        <h2 className={styles.Title}>{title}</h2>
        <div className={styles.LinkContainer}>
          <p className={styles.Subtitle}>{subtitle}</p>
          <div className={styles.IconContainer}>
            <img
              src={graduation}
              className={styles.Graduation}
              alt="Valmistujaislakki"
            />
            <img src={arrow} className={styles.Arrow} alt="Nuoli oikealle" />
          </div>
        </div>
        <div className={styles.Avatars}>
          {expertsToShow.map((answer) => {
            const expert = experts.find((e) => e.name === answer.expertName)

            return (
              <div
                key={expert?.name}
                title={expert?.name}
                className={styles.Avatar}
                style={
                  expert?.image
                    ? {
                        backgroundImage: `url(${require('src/assets/images/' +
                          expert?.image)})`,
                      }
                    : undefined
                }
              />
            )
          })}
          {answers.length > NUMBER_OF_EXPERTS_TO_SHOW && (
            <div className={styles.MoreAvatars}>
              <span className={styles.PlusAvatars}>{`+${
                answers.length - NUMBER_OF_EXPERTS_TO_SHOW
              }`}</span>
            </div>
          )}
        </div>
      </div>
    </button>
  )
}

export default Experts
