import React from 'react'
import styles from './ChallengeYourFriends.module.scss'
import { ReactComponent as FacebookIcon } from 'src/assets/images/facebook-red.svg'
import { ReactComponent as TwitterIcon } from 'src/assets/images/twitter-red.svg'
import { ReactComponent as WhatsAppIcon } from 'src/assets/images/whatsapp-red.svg'
import { uiTexts } from 'src/util/questionData'

interface Props {
  subtitle?: string
}

function ChallengeYourFriends({ subtitle = '' }: Props) {
  const { whatsapp, twitter, title } = uiTexts.some
  const { href } = window.location

  return (
    <div>
      <h3 className={styles.Title}>{title}</h3>
      {subtitle?.length > 0 && <p className={styles.Subtitle}>{subtitle}</p>}
      <div className={styles.Icons}>
        <a
          href={encodeURI(
            `https://www.facebook.com/dialog/feed?app_id=147925155254978&display=popup&link=${href}&redirect_uri=${href}`
          )}
          target="_blank"
          rel="noopener noreferrer"
        >
          <FacebookIcon />
        </a>
        <a
          href={encodeURI(
            `https://twitter.com/intent/tweet?text=${
              twitter.text
            }&url=${href}&hashtags=${twitter.hashtags.join(',')}`
          )}
          target="_blank"
          rel="noopener noreferrer"
        >
          <TwitterIcon />
        </a>
        <a href={encodeURI(`whatsapp://send?text=${whatsapp} ${href}`)}>
          <WhatsAppIcon />
        </a>
      </div>
    </div>
  )
}

export default ChallengeYourFriends
