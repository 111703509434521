import React, { useState, useContext } from 'react'
import Container from 'src/pages/Container'
import styles from './ExpertAnswer.module.scss'
import {
  expertAnswers,
  experts,
  questions,
  uiTexts,
} from 'src/util/questionData'
import { orderBy, groupBy } from 'lodash-es'
import { useEffect } from 'react'
import Expert, { ExpertProps } from 'src/components/Expert/Expert'
import cx from 'classnames'
import NavigationContext from 'src/routes/NavigationContext'
import { ReactComponent as CloseButton } from 'src/assets/images/close-button.svg'
import useScrollPosition from 'src/util/useScrollPosition'

interface Props {
  questionNumber: number
}

enum AnswerType {
  All = 'All',
  Agree = 'Agree',
  Disagree = 'Disagree',
}

const YLE_NAV_HEIGHT = 70

function ExpertAnswer({ questionNumber }: Props) {
  const answersForQuestion =
    expertAnswers.find((e) => e.questionNumber === questionNumber)?.answers ||
    []
  const question =
    questions.find((q) => q.questionNumber === questionNumber)?.question ?? ''

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const expertsToShow: ReadonlyArray<ExpertProps> = answersForQuestion.map(
    (answer) => {
      const e = experts.find((e) => e.name === answer.expertName)

      return {
        name: e?.name ?? '',
        answer: answer.answer as ExpertProps['answer'],
        question,
        image: e?.image ?? '',
        profession: e?.profession ?? '',
        reasoning: answer.reasoning,
        timestamp: e?.answered ?? '',
      }
    }
  )

  const [negativeMargin, setNegativeMargin] = useState(0)
  useScrollPosition((position) => {
    if (position.y <= -YLE_NAV_HEIGHT) {
      setNegativeMargin(-YLE_NAV_HEIGHT)
    } else {
      setNegativeMargin(0)
    }
  })

  const [answerType, setAnswerType] = useState(AnswerType.All)
  const expertsByAnswers = groupBy(expertsToShow, 'answer')
  const filteredExperts =
    answerType === AnswerType.Agree
      ? expertsByAnswers['yes']
      : answerType === AnswerType.Disagree
      ? expertsByAnswers['no']
      : expertsToShow
  const { agree, disagree, title } = uiTexts.question
  const { pop } = useContext(NavigationContext)

  return (
    <>
      <Container style={styles.Container}>
        <div
          className={styles.FixedTopElement}
          style={{
            marginTop: negativeMargin,
          }}
        >
          <div className={styles.TitleContainer}>
            <button onClick={pop} className={styles.CloseIconButton}>
              <CloseButton className={styles.CloseIcon} />
            </button>
            <h1 className={styles.Title}>{title}</h1>
          </div>
          <div className={styles.Buttons}>
            <button
              className={cx(styles.FilterButton, styles.LeftFilterButton)}
              onClick={() =>
                setAnswerType((t) =>
                  t === AnswerType.Agree ? AnswerType.All : AnswerType.Agree
                )
              }
            >
              <span
                className={cx(
                  styles.ButtonContent,
                  styles.Agree,
                  answerType === AnswerType.Agree
                    ? styles.SelectedAgree
                    : undefined
                )}
              >
                {`${agree} (${expertsByAnswers['yes'].length})`}
              </span>
            </button>
            <button
              className={styles.FilterButton}
              onClick={() =>
                setAnswerType((t) =>
                  t === AnswerType.Disagree
                    ? AnswerType.All
                    : AnswerType.Disagree
                )
              }
            >
              <span
                className={cx(
                  styles.ButtonContent,
                  styles.Disagree,
                  answerType === AnswerType.Disagree
                    ? styles.SelectedDisagree
                    : undefined
                )}
              >
                {`${disagree} (${expertsByAnswers['no'].length})`}
              </span>
            </button>
          </div>
        </div>
        <div className={styles.Experts}>
          {orderBy(filteredExperts, 'name').map((e) => (
            <Expert {...e} key={e.name} />
          ))}
        </div>
      </Container>
    </>
  )
}

export default ExpertAnswer
