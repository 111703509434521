import React, { useState, ChangeEvent } from 'react'
import styles from './FeedbackPopup.module.scss'
import { ReactComponent as LikeButton } from 'src/assets/images/like-button.svg'
import { ReactComponent as DislikeButton } from 'src/assets/images/dislike-button.svg'
import Button, { ButtonType } from '../Button/Button'
import { CSSTransition } from 'react-transition-group'
import { postLearned, postFeedback } from 'src/api/rest'
import { isUndefined } from 'lodash-es'
import { uiTexts } from 'src/util/questionData'

const currentYear = new Date().getFullYear()
const years = Array.from({ length: 110 }, (_, index) => currentYear - index)

function FeedbackPopup() {
  const [yearOfBirth, setYearOfBirth] = useState<string | undefined>()
  const [opinion, setOpinion] = useState<string | undefined>()
  const [gender, setGender] = useState<string | undefined>()
  const [learnedSomething, setLearnedSomething] = useState<
    boolean | undefined
  >()

  function learned() {
    setLearnedSomething(true)

    postLearned(true).catch((e) => {
      console.log('failed to post feedback')
    })
  }

  function didNotLearn() {
    setLearnedSomething(false)

    postLearned(false).catch((e) => {
      console.log('failed to post feedback')
    })
  }

  function onSubmitForm(e: any) {
    e.preventDefault()

    postFeedback({
      learned: learnedSomething!,
      opinion,
      yearOfBirth,
      gender,
    }).catch((e) => {
      console.log('failed to post feedback')
    })

    setOpinion(undefined)
    setGender(undefined)
    setYearOfBirth(undefined)
    setLearnedSomething(undefined)
  }

  const {
    title,
    opinionPlaceholder,
    ageLabel,
    agePlaceholder,
    opinionLabel,
    genderLabel,
    female,
    male,
    other,
    send,
  } = uiTexts.feedback

  return (
    <div className={styles.FeedbackPopup}>
      <h2 className={styles.Title}>{title}</h2>
      <div className={styles.Buttons}>
        <button className={styles.LearnButton} onClick={learned}>
          <LikeButton />
        </button>
        <button className={styles.LearnButton} onClick={didNotLearn}>
          <DislikeButton />
        </button>
      </div>
      <CSSTransition
        in={!isUndefined(learnedSomething)}
        timeout={200}
        classNames={styles}
        unmountOnExit
      >
        <form onSubmit={onSubmitForm}>
          <div className={styles.InputField}>
            <label className={styles.InputLabel} htmlFor="opinion">
              {opinionLabel}
            </label>
            <textarea
              className={styles.Input}
              id="opinion"
              value={opinion}
              onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
                setOpinion(e.currentTarget.value)
              }
              placeholder={opinionPlaceholder}
            />
          </div>

          <div className={styles.InputField}>
            <label className={styles.InputLabel} htmlFor="yearOfBirth">
              {ageLabel}
            </label>
            <select
              className={styles.Input}
              id="yearOfBirth"
              value={yearOfBirth}
              onChange={(e: ChangeEvent<HTMLSelectElement>) =>
                setYearOfBirth(e.currentTarget.value)
              }
            >
              <option value="">{agePlaceholder}</option>
              {years.map((year) => (
                <option value={year} key={year}>
                  {year}
                </option>
              ))}
            </select>
          </div>

          <div className={styles.InputField}>
            <h4 className={styles.RadioGroupLabel}>{genderLabel}</h4>
            <div className={styles.RadioGroup}>
              <div>
                <input
                  type="radio"
                  id="gender-female"
                  name="gender"
                  value="female"
                  checked={gender === 'female'}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    setGender(e.currentTarget.value)
                  }
                />
                <label htmlFor="gender-female">{female}</label>
              </div>
              <div>
                <input
                  type="radio"
                  id="gender-male"
                  name="gender"
                  value="male"
                  checked={gender === 'male'}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    setGender(e.currentTarget.value)
                  }
                />
                <label htmlFor="gender-male">{male}</label>
              </div>
              <div>
                <input
                  type="radio"
                  id="gender-other"
                  name="gender"
                  value="other"
                  checked={gender === 'other'}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    setGender(e.currentTarget.value)
                  }
                />
                <label htmlFor="gender-other">{other}</label>
              </div>
            </div>
          </div>

          <Button
            text={send}
            type={ButtonType.neutral}
            className={styles.SendButton}
          />
        </form>
      </CSSTransition>
    </div>
  )
}

export default FeedbackPopup
