import React from 'react'
import Container from '../Container'
import styles from './Start.module.scss'
import { ReactComponent as ArrowButton } from '../../assets/images/arrow-button.svg'
import browserRoutes from 'src/routes/browser'
import background from '../../assets/images/undraw_a_better_world_9xfd.png'
import { uiTexts } from 'src/util/questionData'
import { useContext } from 'react'
import NavigationContext from 'src/routes/NavigationContext'
import yleNewsLab from 'src/assets/images/yle-news-lab.svg'

function Start() {
  const { title, subtitle, description, link, linkUrl } = uiTexts.front
  const { push } = useContext(NavigationContext)

  return (
    <>
      <img
        className={styles.Background}
        src={background}
        alt="Ryhmä ihmisiä eri harrastuksissa"
      />
      <Container style={styles.Container}>
        <div className={styles.Content}>
          <h1 className={styles.Title}>{title}</h1>
          <h2 className={styles.SubTitle}>{subtitle}</h2>
          <p className={styles.Description}>{description}</p>
          <button
            className={styles.Button}
            onClick={() =>
              push({
                name: browserRoutes.question,
                params: {
                  questionNumber: 1,
                },
              })
            }
          >
            <ArrowButton className={styles.ArrowButton} />
          </button>
          {linkUrl.length > 0 && (
            <>
              <a
                href={linkUrl}
                className={styles.Link}
                target="_blank"
                rel="noopener noreferrer"
              >
                {link}
              </a>
              <img
                src={yleNewsLab}
                alt="Yle News Lab"
                className={styles.YleNewsLab}
              />
            </>
          )}
        </div>
      </Container>
    </>
  )
}

Start.displayName = 'Start'

export default Start
