import React, { MouseEvent, useState } from 'react'
import Button, { ButtonType } from 'src/components/Button/Button'
import DemographyBars from 'src/components/DemographyBars/DemographyBars'
import QuestionProgress from 'src/components/QuestionProgress/QuestionProgress'
import browserRoutes from 'src/routes/browser'
import { ExpertData, PopulationData, uiTexts } from 'src/util/questionData'
import styles from './Question.module.scss'
import Container from '../Container'
import { useContext } from 'react'
import NavigationContext from 'src/routes/NavigationContext'
import { useEffect } from 'react'
import AnswersContext from 'src/util/AnswersContext'
import { Link, scroller, Element } from 'react-scroll'
import { postAnswers } from 'src/api/rest'
import Statement from 'src/components/Statement'
import arrow from 'src/assets/images/arrow-forward.svg'
import HideExperts from 'src/components/HideExperts'
import TransitionQuestion, {
  transitionDuration,
} from 'src/hoc/TransitionQuestion/TransitionQuestion'
import Experts from 'src/components/Experts'

interface Props {
  questionNumber: number
  lastQuestionNumber: number
  expertAnswers: ExpertData['answers']
  populationAnswers: PopulationData
  image: any
  imageAlt: string
}

type Answer = 'yes' | 'no'
const SCROLL_TARGET_ID = 'other-answers'

export default function Question(props: Props) {
  const {
    questionNumber,
    lastQuestionNumber,
    populationAnswers,
    image,
    imageAlt,
  } = props
  const { answers, dispatch, done, hideExperts } = useContext(AnswersContext)
  const answer = answers[`${questionNumber}`]
  const { push, pop, current } = useContext(NavigationContext)
  const [definitionVisible, setDefinitionVisible] = useState(false)
  const transitionRef = React.useRef(null)

  useEffect(() => {
    // navigation from the expert answer screen will set
    // the keepScrollPosition to true
    if (!current?.params?.keepScrollPosition) {
      setTimeout(() => {
        window.scrollTo(0, 0)
      }, transitionDuration)
    } else {
      scroller.scrollTo(SCROLL_TARGET_ID, {
        duration: 0,
        delay: 0,
      })
    }
  }, [answer, current])

  const handleNextClicked = () => {
    if (questionNumber === lastQuestionNumber) {
      // save answers to database when the last question is
      // answered and they're not yet saved i.e. the user has not
      // navigated back and forth from the results screen
      if (!done) {
        const answersBody = Object.entries(answers).map(([k, v]) => ({
          questionNumber: Number(k),
          answer: v,
        }))

        postAnswers(answersBody)
          .then(() => {
            dispatch({ type: 'done', payload: {} })
          })
          .catch((e) => {
            throw e
          })
      }

      push({
        name: browserRoutes.end,
      })
    } else {
      push({
        name: browserRoutes.question,
        params: {
          questionNumber: questionNumber + 1,
        },
      })
    }
  }

  const handlePreviousClicked = () => {
    if (questionNumber > 1) {
      pop({ keepScrollPosition: false })
    }
  }

  const handleAnswerClicked = (e: MouseEvent<HTMLButtonElement>) => {
    const answer = e.currentTarget.value as Answer
    dispatch({
      type: 'answer',
      payload: { questionNumber: `${questionNumber}`, answer },
    })
  }

  function onOpenDefinition() {
    setDefinitionVisible(true)
  }

  const { nextButton } = uiTexts.answer
  const { yesButton, noButton } = uiTexts.common

  return (
    <TransitionQuestion nodeRef={transitionRef} transitionKey={questionNumber}>
      <>
        {definitionVisible && <div className={styles.BackDrop}></div>}
        <Container>
          <div className={styles.HeroContainer}>
            <img
              src={image}
              className={styles.HeroIllustration}
              alt={imageAlt}
            />
          </div>
          <QuestionProgress
            lastQuestionNumber={lastQuestionNumber}
            questionNumber={questionNumber}
            answerIsGiven={Boolean(answer)}
            onNextClick={handleNextClicked}
            onPreviousClick={handlePreviousClicked}
          />
          <Statement
            questionNumber={questionNumber}
            onOpenDefinition={onOpenDefinition}
            definitionVisible={definitionVisible}
            onCloseDefinition={() => setDefinitionVisible(false)}
          />
          <div className={styles.ButtonContainer}>
            <Link
              to={SCROLL_TARGET_ID}
              smooth
              duration={700}
              className={styles.ButtonWrapper}
            >
              <Button
                className={styles.PadRight}
                type={
                  answer === 'no'
                    ? ButtonType.disabledSubmit
                    : ButtonType.submit
                }
                text={yesButton}
                value={'yes'}
                onClick={handleAnswerClicked}
              />
            </Link>
            <Link
              to={SCROLL_TARGET_ID}
              smooth
              duration={700}
              className={styles.ButtonWrapper}
            >
              <Button
                className={styles.PadLeft}
                type={
                  answer === 'yes'
                    ? ButtonType.disabledCancel
                    : ButtonType.cancel
                }
                text={noButton}
                value={'no'}
                onClick={handleAnswerClicked}
              />
            </Link>
          </div>

          <Element name={SCROLL_TARGET_ID}></Element>

          {(answer || !hideExperts) && (
            <Experts
              answers={props.expertAnswers}
              questionNumber={questionNumber}
            />
          )}

          {!answer && !hideExperts && (
            <HideExperts
              onHideExperts={() =>
                dispatch({
                  type: 'hideExperts',
                  payload: {},
                })
              }
            />
          )}

          {answer && <div className={styles.Line}></div>}
          {answer && <DemographyBars answers={populationAnswers.answers} />}

          {answer && (
            <Button
              className={styles.NextButton}
              type={ButtonType.neutral}
              text={nextButton}
              onClick={handleNextClicked}
              icon={arrow}
              alt="Nuoli oikealle"
            />
          )}
        </Container>
      </>
    </TransitionQuestion>
  )
}
