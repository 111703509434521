import React from 'react'
import {
  PopulationData,
  uiTexts,
  segments as segmentNames,
} from 'src/util/questionData'
import Bar from '../Bar/Bar'
import styles from './DemographyBars.module.scss'
import { ReactComponent as Info } from 'src/assets/images/info.svg'

interface Props {
  answers: PopulationData['answers']
}

export default function DemographyBars({ answers }: Props) {
  const answersToShow = Object.entries(answers)
  const { title } = uiTexts.answer

  return (
    <section className={styles.Container}>
      <div className={styles.TitleContainer}>
        <Info />
        <h2 className={styles.Title}>{title}</h2>
      </div>

      <div className={styles.Answers}>
        {answersToShow.map(([key, value]) => (
          <Bar
            label={(segmentNames as { [_: string]: string })[key]}
            percentage={value ?? 0}
            key={key}
          />
        ))}
      </div>
    </section>
  )
}
