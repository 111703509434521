import React from 'react'
import styles from './HideExperts.module.scss'
import { uiTexts } from 'src/util/questionData'

interface Props {
  onHideExperts: () => void
}

function HideExperts({ onHideExperts }: Props) {
  const { hideExpertsDescription, hideExpertsTitle } = uiTexts.question

  return (
    <div>
      <p className={styles.Description}>{hideExpertsDescription}</p>
      <button className={styles.ExpertButton} onClick={onHideExperts}>
        <span className={styles.ButtonTitle}>{hideExpertsTitle}</span>
      </button>
    </div>
  )
}

export default HideExperts
